import React, { Component } from 'react';
import '../../../../assets/scss/components/blocks/blocks.scss';
import classNames from 'classnames';

class Paragraph extends Component {
  render() {
    return (
      <div className={classNames('block file',{['align-'+this.props.attributes.align]:this.props.attributes.align})} dangerouslySetInnerHTML={{__html:this.props.data}} >
      </div>
    );
  }
}

export default Paragraph;
