import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import '../../assets/scss/components/elements/Image.scss';
import { cacheMedia } from '../../store/content/actions';

class Image extends Component {
  constructor(props){
    super(props);
    this.image = React.createRef();
    this.state = {
      width: -1
    }
  }
  checkWidth = () => {
    if(this.props.width){
      if(this.state.width!==this.props.width) {
        this.setState({width: this.props.width});
      }
    } else if ( this.image.current){
      if(this.state.width!==this.image.current.clientWidth) {
        this.setState({width:this.image.current.clientWidth});
      }
    } else {
      if(this.state.width!==window.innerWidth) {
        this.setState({width: window.innerWidth});
      }
    }
  }
  componentDidMount() {
    this.checkWidth();
  }
  componentDidUpdate() {
    this.checkWidth();
  }
  render() {
    const { media } = this.props;
    let image = media.find(media=>media.id===this.props.id);
    let thumbnail;
    let url;
    let alt;
    let style = this.props.style;
    if(image){
      const { size } = image;
      alt = image.alt;
      thumbnail = size.medium.url;
      url = thumbnail;
      for( let key in size ){
        if(this.props.ratio&&size[key]===size.thumbnail){
          continue;
        }
        if( size[key].width < this.state.width ){
          url = size[key].url;
          continue;
        }
        url = size[key].url;
        break;
      }
    }else{
      return <></>
    }
    return (
      <div className={classNames('Image',this.props.className)} ref={this.image}>
        <LazyLoadImage
          {...(alt ? {alt: alt} : {})}
          {...(this.props.width ? {width: this.props.width} : {})}
          afterLoad={()=>{
            let that = this;
            if(!image.cached){setTimeout(()=>that.props.cacheMedia(image.id),300)}
          }}
          placeholderSrc={thumbnail}
          placeholder=<div></div>
          src={url}
          effect='blur'
          threshold={window.innerHeight}
          visibleByDefault={image.cached}
          {...(style ? {style: style} : {})}
          />
      </div>
    )
  }
}
function mapStateToProps(reduxState, props){
  return {
    media: reduxState.content.media,
  }
}
export default connect(mapStateToProps,{ cacheMedia })(Image);





    // if(this.image.current){
    //   var lazyImages = [this.image.current];
    //   if ("IntersectionObserver" in window) {
    //     let lazyImageObserver = new IntersectionObserver(function(entries, observer) {
    //       entries.forEach(function(entry) {
    //         if (entry.isIntersecting) {
    //           let lazyImage = entry.target;
    //           lazyImage.src = lazyImage.dataset.src;
    //           lazyImage.classList.remove("lazy");
    //           lazyImageObserver.unobserve(lazyImage);
    //         }
    //       });
    //     });
    //
    //     lazyImages.forEach(function(lazyImage) {
    //       lazyImageObserver.observe(lazyImage);
    //     });
    //   } else {
    //   }
    // }
