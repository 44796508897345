import React, { Component } from 'react';
import '../../../../assets/scss/components/blocks/blocks.scss';

class Separator extends Component {
  render() {
    return (
      <div className='block separator' dangerouslySetInnerHTML={{__html:this.props.data}} />
    );
  }
}

export default Separator;
