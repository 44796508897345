import React, { Component } from 'react';
import '../../../../assets/scss/components/blocks/blocks.scss';
class Heading extends Component {
  render() {
    return (
      <div className='block heading' dangerouslySetInnerHTML={{__html:this.props.data}}>
      </div>
    );
  }
}

export default Heading;
