import { Component } from 'react';
import { connect } from 'react-redux';

class Privacy extends Component {
  render() {
    return this.props.children;
  }
}
function mapStateToProps(reduxState) {
  return {
    general: reduxState.settings.settings.general,
  }
}
export default connect(mapStateToProps,{})(Privacy);
