import React, { Component } from 'react';
import { connect } from 'react-redux';
import Maps from '../elements/Maps';
import Image from '../elements/Image';
import '../../assets/scss/components/templates/Footer.scss';
import { NavLink, Link } from 'react-router-dom';
import { findIcon } from '../../helpers/icons';

class Footer extends Component {
  render() {
    const { api, colors, footer, menu } = this.props;
    return (
      <>
        {
          (footer&&footer.prefooter)&&
          <ul className='prefooter'>
            {
              footer.prefooter.sections.map((d,i)=>{
                return <li key={'prefooter_'+i} dangerouslySetInnerHTML={{__html:d.section}}/>
              })
            }
          </ul>
        }
        {
          api.api&&<Maps api={api} colors={colors}/>
        }
        {
          footer&&
          <footer className='Footer'>
            <div className='inner'>
              {
                footer.logo&&<Link to='/' aria-label='Logo'><Image className='logo' ratio id={footer.logo} /></Link>
              }
              {
                menu&&<nav>
                  <ul>
                    {
                      menu.nav_menu_items.map((d,i)=>{
                        return <li key={'footer_'+i}>
                          {
                            d.object==='custom'?
                              <a href={d.slug} target='_blank' rel='noopener noreferrer'>{d.title}</a>
                            :
                              <NavLink to={'/'+d.slug} activeClassName='selected'>{d.title}</NavLink>
                          }
                        </li>;
                      })
                    }
                  </ul>
                </nav>
              }
              {
                footer.social_media&&<nav>
                  <ul>
                    {
                      footer.social_media.map((d,i)=>{
                        var icon = findIcon(d.url);
                        return <li key={'footer_social_'+i}>
                          <a href={d.url} target='_blank' rel='noopener noreferrer' aria-label={icon.name}>{icon.icon}</a>
                        </li>;
                      })
                    }
                  </ul>
                </nav>
              }
            </div>
          </footer>
        }
      </>
    );
  }
}

function mapStateToProps(reduxState, props){
  let footer = reduxState.settings.settings.footer;
  return {
    footer: footer,
    menu: reduxState.content.menus.find(menu=>menu.id+''===footer.menu),
    api: reduxState.settings.settings.integration.google_maps,
    colors: reduxState.settings.settings.customizer.colors
  }
}
export default connect(mapStateToProps,{  })(Footer);
