import React, { Component } from 'react';
import '../../../../assets/scss/components/blocks/blocks.scss';

class Audio extends Component {
  render() {
    return (
      <div className='block audio' dangerouslySetInnerHTML={{__html:this.props.data}}>
      </div>
    );
  }
}

export default Audio;
