import React, { Component } from 'react';
import '../../../../assets/scss/components/blocks/blocks.scss';
import ImageElement  from '../../../elements/Image';
import classNames from 'classnames';

class Image extends Component {
  render() {
    return (
      <div className='block image'>
        <figure className={classNames({['align-'+this.props.attributes.align]:this.props.attributes.align})}>
          <ImageElement id={this.props.attributes.id} width={this.props.attributes.align==='full'?window.innerWidth:this.props.attributes.width}/>
        </figure>
      </div>
    );
  }
}

export default Image;
