import React, { Component } from 'react';
import { connect } from 'react-redux';
import { init } from '../store/content/actions';
import '../assets/scss/components/App.scss';
import Layout from './templates/Layout';
import Privacy from './templates/Privacy';
import Theme from './templates/Theme';
import Helmet from 'react-helmet';
class App extends Component {
  componentDidMount() {
    this.props.init();
  }
  render() {
    return (
      <div className='App'>
        <Helmet>
          <title></title>
          <meta name='description' content='' />
        </Helmet>
        {
          this.props.init&&<Theme><Privacy><Layout/></Privacy></Theme>
        }
      </div>
    );
  }
}

function mapStateToProps(reduxState, props){
  return {
    init: reduxState.settings.init,
  }
}
export default connect(mapStateToProps,{init})(App);
