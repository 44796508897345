import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { getBlock, isBlockAllowed, getPagination } from '../../helpers/blocks';
import '../../assets/scss/components/templates/Post.scss';
import { Route } from 'react-router-dom';
import FourHundredFour from './FourHundredFour';
import { maybeRequestPost } from '../../store/content/actions';
class Post extends Component {
  componentDidMount() {
    this.props.maybeRequestPost(this.props.match.params.uri);
    setTimeout(()=> window.scrollTo({top:0,behavior:'smooth'}),10);
  }
  componentDidUpdate(prev) {
    if(prev.match.params.uri!==this.props.match.params.uri) {
      this.props.maybeRequestPost(this.props.match.params.uri);
      setTimeout(()=> window.scrollTo({top:0,behavior:'smooth'}),10);
    }
  }
  render() {
    const { post,loader,init } = this.props;
    let { id } = this.props.match.params;
    id = id?parseInt(id):1;
    let pagination = getPagination(post?post.content:'');
    return (
      <>
        {
          (!post||pagination.length<id)?(!loader&&init)?<Route component={FourHundredFour} />:'':<article className='Post'>
            <Helmet>
              <title>{post.seo.title}</title>
              <meta name='Description' content={post.seo.description}/>
              <link rel='canonical' href={window.location.href} />
              <meta property='og:title' content={post.seo.og.title}/>
              <meta property='og:description' content={post.seo.og.description}/>
              <meta property='og:image' content={post.seo.og.image}/>
            </Helmet>
            {
              post.show_title&&<header className='header'>
                <h1>{post.title}</h1>
              </header>
            }
            <section className='content'>
              {
                pagination[id-1].filter(d=>isBlockAllowed(d.blockName)).map((d,i)=>{
                  const Block = getBlock(d.blockName);
                  return <Block key={post.uri+'_blocks_'+i} id={post.uri+'_blocks_'+i} attributes={d.attrs} data={d.innerHTML} block={d} pageination={
                    d.blockName==='core/nextpage'?{
                      count:getPagination(post.content),
                      current:id,
                      uri:this.props.match.params.uri,
                    }:false
                  } />
                })
              }
            </section>
          </article>
        }
      </>
    );
  }
}

function mapStateToProps(reduxState, props) {
  let post = reduxState.content.posts.find(post => post.uri===props.match.params.uri);
  return {
    loader: reduxState.settings.loader,
    init: reduxState.settings.init,
    post: post,
  }
}
export default connect(mapStateToProps,{maybeRequestPost})(Post);
