import React, { Component } from 'react';
import '../../../../assets/scss/components/blocks/blocks.scss';
import classNames from 'classnames';

class Video extends Component {
  render() {
    return (
      <div className={classNames('block video',{['align-'+this.props.attributes.align]:this.props.attributes.align})} dangerouslySetInnerHTML={{__html:this.props.data}} />
    );
  }
}

export default Video;
