import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Image from '../elements/Image';
import '../../assets/scss/components/templates/Header.scss';
import { NavLink, Link } from 'react-router-dom';
import { findIcon, getIcon } from '../../helpers/icons';
import { setHamburger } from '../../store/settings/actions';

class HeaderMobile extends Component {
  state = {
    slideOut:false,
  }
  render() {
    const { logo, social_media } = this.props.data;
    const { menu } = this.props;
    return (
      <div className={classNames('HeaderMobile',{slideOut:this.state.slideOut})} onClick={(e)=>{
        if(e.target.classList.contains('HeaderMobile')){
          this.setState({slideOut:true},
            ()=>setTimeout(()=>{
              this.props.setHamburger(false);
            },500)
          );
        }
      }}>
        <div className='inner'>
          <div className='close' onClick={()=>{
            this.setState({slideOut:true},
              ()=>setTimeout(()=>{
                this.props.setHamburger(false);
              },500)
            );
          }}>
            {
              getIcon('times')
            }
          </div>
          {
            logo&&<Image className='logo' ratio id={logo} />
          }
          {
            menu&&<nav>
              <ul>
                {
                  menu.nav_menu_items.map((d,i)=>{
                    return <li key={'header_mobile_'+i}>
                      {
                        d.object==='custom'?
                          <a href={d.slug} target='_blank' rel='noopener noreferrer'>{d.title}</a>
                        :
                          <NavLink to={'/'+d.item===this.props.landingpage?'':d.slug} onClick={()=>{
                            this.setState({slideOut:true},
                              ()=>setTimeout(()=>{
                                this.props.setHamburger(false);
                              },500)
                            );
                          }} activeClassName='selected'>{d.title}</NavLink>
                      }
                    </li>;
                  })
                }
              </ul>
            </nav>
          }
          {
            social_media&&<nav className='social'>
              <ul>
                {
                  social_media.map((d,i)=>{
                    var icon = findIcon(d.url);
                    return <li key={'header_mobile_social_'+i}>
                      <a href={d.url} target='_blank' rel='noopener noreferrer' aria-label={icon.name}>{icon.icon}</a>
                    </li>;
                  })
                }
              </ul>
            </nav>
          }
        </div>
      </div>
    )
  }
}

function mapStateToPropsMobile(reduxState, props){
  return {
  }
}
const ConnectedHeaderMobile = connect(mapStateToPropsMobile,{ setHamburger })(HeaderMobile);

class Header extends Component {
  state = {
    top:true,
  }
  onScroll = () => {
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if(this.state.top&&scrollTop>0){
      this.setState({top:false});
    }else if(!this.state.top&&scrollTop===0){
      this.setState({top:true});
    }
  }
  componentDidMount() {
    window.addEventListener('scroll',this.onScroll,true);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll',this.onScroll,true);
  }
  toggleHamburger = (e) => {
    if(e.target.classList.contains('hamburger') || e.target.parentElement.classList.contains('hamburger') || e.target.parentElement.parentElement.classList.contains('hamburger') ){
      this.props.setHamburger(true);
    }
  }
  render() {
    const { header, menus, menusMobile, hamburger } = this.props;
    return (
      <>
        {
          header&&
          <header className={classNames('Header',{top:this.state.top})}>
            <div className='inner'>
              {
                header.desktop.logo&&<Link to='/' aria-label='Logo'><Image className='logo' ratio id={header.desktop.logo} /></Link>
              }
              {
                menusMobile&&<div className='hamburger' onClick={this.toggleHamburger}>
                  {
                    getIcon('bars')
                  }
                  {
                    hamburger===true&&<ConnectedHeaderMobile menu={menusMobile} data={header.mobile} />
                  }
                </div>
              }
              {
                menus&&<nav>
                  <ul>
                    {
                      menus.nav_menu_items.map((d,i)=>{
                        return <li key={'header_desktop_'+i}>
                          {
                            d.object==='custom'?
                              <a href={d.slug} target='_blank' rel='noopener noreferrer'>{d.title}</a>
                            :
                              <NavLink to={'/'+d.item===this.props.landingpage?'':d.slug} activeClassName='selected'>{d.title}</NavLink>
                          }
                        </li>;
                      })
                    }
                  </ul>
                </nav>
              }
            </div>
          </header>
        }
      </>
    );
  }
}

function mapStateToProps(reduxState, props){
  let header = reduxState.settings.settings.header;
  return {
    header: header,
    menus: reduxState.content.menus.find(menu=>menu.id+''===header.desktop.menu),
    menusMobile: reduxState.content.menus.find(menu=>menu.id+''===header.mobile.menu),
    hamburger: reduxState.settings.hamburger,
    landingpage: reduxState.settings.settings.general.landingpage
  }
}
export default connect(mapStateToProps,{ setHamburger })(Header);
