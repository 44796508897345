import LazyLink from '../components/elements/LazyLink';

export function getElement(name){
  switch (name) {
    case 'LazyLink':
      return LazyLink;
    default:
      return name;
  }
}
