import React, { Component } from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';
import '../../assets/scss/components/elements/Maps.scss';
import { withCookies } from 'react-cookie';

class Maps extends Component {
  constructor(props){
    super(props);
    this.component = React.createRef();
  }
  LoadMap = () => {
    const { api, colors } = this.props;
    let coord = {lat: parseFloat(api.data.lat), lng: parseFloat(api.data.lng)};
    this.map = new window.google.maps.Map(this.component.current, {
      center: coord,
      zoom: 14,
      styles: [
        {
          featureType: 'administrative',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: colors.color_3
            }
          ]
        },
        {
          featureType: 'landscape',
          elementType: 'all',
          stylers: [
            {
              'color': colors.color_4
            }
          ]
        },
        {
          featureType: 'poi',
          elementType: 'all',
          stylers: [
            {
              'visibility': 'off'
            }
          ]
        },
        {
          featureType: 'road',
          elementType: 'all',
          stylers: [
            {
              color: colors.color_5
            },
            {
              saturation: -100
            },
            {
              lightness: 45
            }
          ]
        },
        {
          featureType: 'road.highway',
          elementType: 'all',
          stylers: [
            {
              visibility: 'simplified'
            }
          ]
        },
        {
          featureType: 'road.arterial',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'transit',
          elementType: 'all',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'water',
          elementType: 'all',
          stylers: [
            {
              color: colors.color_1
            },
            {
              visibility: 'on'
            }
          ]
        }
      ]
    });
    this.infowindow = new window.google.maps.InfoWindow({
      content: '<div style="color:'+colors.color_3+'">'+api.data.address+'</div>',
    });
    this.marker = new window.google.maps.Marker(
      {
        position: coord,
        map: this.map,
        animation: window.google.maps.Animation.DROP,
      }
    );
    this.marker.addListener('click',()=>{
      this.infowindow.open(this.map,this.marker)
    });
  }
  maybeLazyLoadMap = (e) => {
    const maps = this.props.cookies.get('DGR_GMAPS');
    if(maps){
      const comp = this.component.current;
      let that = this;
      if ('IntersectionObserver' in window) {
        let observer = new IntersectionObserver(function(entries, observer) {
          entries.forEach(function(entry) {
            if (entry.isIntersecting) {
              that.LoadMap();
              observer.unobserve(comp);
            }
          });
        });
        observer.observe(comp);
      } else {
        that.LoadMap();
      }
    }
    e.target.removeEventListener('load',this.maybeLazyLoadMap);
  }
  componentDidMount(){
    const maps = this.props.cookies.get('DGR_GMAPS');
    if(maps){
      const { api } = this.props;
      const script = document.createElement('script');
      script.src = 'https://maps.googleapis.com/maps/api/js?key='+api.api;
      window.document.body.appendChild(script);
      script.addEventListener('load',this.maybeLazyLoadMap);
    }
  }
  componentDidUpdate(){
  }
  updateStorage = () => {
    let cookieDate = new Date();
    cookieDate.setFullYear(cookieDate.getFullYear()+1);
    this.props.cookies.set('DGR_GMAPS',true,{path:'/', expires: cookieDate});
    const { api } = this.props;
    const script = document.createElement('script');
    script.src = 'https://maps.googleapis.com/maps/api/js?key='+api.api;
    window.document.body.appendChild(script);
    script.addEventListener('load',this.maybeLazyLoadMap);
  }
  render() {
    const maps = this.props.cookies.get('DGR_GMAPS');
    return (
      <section className='Maps' ref={this.component}>
        {
          maps!==true?<div className='notice'>
            <p>Mit den Laden der Karte aktzeptieren Sie die Datenschutzerklärung von Google Maps</p>
            <p><a href='https://policies.google.com/privacy' target='_blank' rel='noopener noreferrer' >Mehr Erfahren</a></p>
            <button onClick={(e)=>this.updateStorage()}>Karte Laden</button>
          </div>:''
        }
      </section>
    )
  }
}
export default withCookies(Maps)
