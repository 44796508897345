import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../assets/scss/components/templates/FourHundredFour.scss';
import Helmet from 'react-helmet';
class FourHundredFour extends Component {
  componentDidMount() {    
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className='FourHundredFour'>
        <Helmet>
          <title>404</title>
        </Helmet>
        <h1>404 - Seite nicht gefunden</h1>
      </div>
    );
  }
}

export default connect(null,{})(FourHundredFour);
