import { Component } from 'react';
import { connect } from 'react-redux';

class Theme extends Component {
  componentDidUpdate(){
    const { customizer } = this.props;
    for( let styles in customizer ){
      for( let prop in customizer[styles] ){
        document.documentElement.style.setProperty( '--'+prop, customizer[styles][prop] );
      }
    }
  }
  render(){
    return this.props.children;
  }
}
function mapStateToProps(reduxState){
  return {
    customizer: reduxState.settings.settings.customizer,
  }
}
export default connect(mapStateToProps,{})(Theme);
