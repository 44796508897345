import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import Helmet from 'react-helmet';
import FourHundredFour from './FourHundredFour';
import Home from './Home';
import Post from './Post';
import Header from './Header';
import Footer from './Footer';
class Layout extends Component {
  render() {
    return (
      this.props.init&&
        <>
          <Helmet>
            <title>test</title>
            <meta name='Description' content='Die Gebäudereiniger'/>
            <link rel='shortcut icon' href={this.props.icon} />
            <link rel='apple-touch-icon' href={this.props.icon} />
          </Helmet>
          <Header/>
          <Switch>
            <Route exact path='/' component={Home}/>
            {
              this.props.settings.seo&&this.props.settings.seo.redirects&&this.props.settings.seo.redirects.map((d,i)=>{
                return <Route key={'redirects_global_'+i} exact path={'/'+d.from} render={()=>{
                  return d.type==='inbound'?<Redirect to={d.to}/>:window.location.href = d.to
                }}/>
              })
            }
            <Route path='/:uri+/:id(\d*)' component={Post}/>
            <Route path='/:uri+' component={Post}/>
            <Route path='*' component={FourHundredFour} />
          </Switch>
          <Footer/>
        </>
    );
  }
}

function mapStateToProps(reduxState, props){
  let general = reduxState.settings.settings.general;
  var icon;
  if(general) {
    icon = reduxState.content.media.find(media=>media.id===general.favicon);
    if(icon) {
      icon = icon.size.thumbnail.url;
    }
  }
  return {
    init: reduxState.settings.init,
    icon: icon,
    settings: reduxState.settings.settings,
  }
}
export default connect(mapStateToProps,{})(Layout);
