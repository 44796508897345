import { CONTACT_PATH } from '../../config/api';
import sendRequest from '../../helpers/api';
import { INIT_SETTINGS, SET_LOADER, SUBMITTED, SET_HAMBURGER } from './types';

export function initSettings( settings ) {
  return {
    type: INIT_SETTINGS, settings
  }
}
export function setLoader(value, init) {
  return {
    type: SET_LOADER, value, init
  }
}

export function setSubmitted(value){
  return {
    type:SUBMITTED,value: value
  }
}

export function setHamburger(value){
  return {
    type:SET_HAMBURGER,value: value
  }
}

export function sendMail(id,to, data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      var form = {};
      data.forEach((d,i)=>{
        form[i]= d;
      });
      if(to===''){
        to = 'default';
      }
      return sendRequest('POST', CONTACT_PATH+id+'/'+to, JSON.stringify(form), { headers: {'Content-Type': 'multipart/form-data' }})
        .then(
          res => {
            dispatch(setSubmitted(true));
            resolve();
          }
        )
        .catch(err => {
          reject();
        });
    });
  };
}
