import update from 'react-addons-update';
import { ADD_MEDIA, ADD_MENU, ADD_POST, CACHE_MEDIA } from './types';

const DEFAULT_STATE = {
  authors: [], media: [], menus: [], posts:[]
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ADD_POST:{
      if(state.posts.find(post=>post.id===action.data.id)){
        return state
      }
      return (
        update(state,{
          posts:{
            $push: [action.data]
          }
        })
      )
    }
    case ADD_MEDIA:{
      if(state.media.find(media=>media.id===action.data.id)){
        return state
      }
      return (
        update(state,{
          media:{
            $push: [action.data]
          }
        })
      )
    }
    case ADD_MENU:{
      if(state.menus.find(menu=>menu.id===action.data.id)){
        return state
      }
      return (
        update(state,{
          menus:{
            $push: [action.data]
          }
        })
      )
    }
    case CACHE_MEDIA:{
      let index = state.media.findIndex(menu=>menu.id===action.id);
      return (
        update(state,{
          media:{
            [index]: {
              cached: {
                $set: true
              }
            }
          }
        })
      )
    }
    default:
      return state;
  }
};
