import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'react-lazy-load-image-component/src/effects/blur.css';
import '../../assets/scss/components/elements/Image.scss';
import { Link } from 'react-router-dom';
import { maybeRequestPost } from '../../store/content/actions';

class LazyLink extends Component {
  constructor(props){
    super(props);
    this.link = React.createRef();
  }
  handleScroll = () => {
    if ( this.link.current && !this.props.lazy ) {
      let rect = this.link.current.getBoundingClientRect();
      let viewport = rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      if ( viewport ) {
        window.removeEventListener('scroll', this.handleScroll, true);
        this.props.maybeRequestPost(this.props.attributes.href);
      }
    }
  }
  componentDidMount(){
    if(!this.props.lazy) {
      window.addEventListener('scroll', this.handleScroll, true);
        this.handleScroll();
    }
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, true);
  }

  render() {
    return (
      <span ref={this.link}>
        <Link className={this.props.className} to={this.props.attributes.href}>{this.props.content}</Link>
      </span>
    )
  }
}
function mapStateToProps(reduxState, props){
  return {
    lazy: reduxState.content.posts.find(post=>post.uri===props.attributes.href),
  }
}
export default connect(mapStateToProps,{ maybeRequestPost })(LazyLink);
