import React, { Component } from 'react';
import classNames from 'classnames';
import '../../../../assets/scss/components/blocks/blocks.scss';
import { Link } from 'react-router-dom';

class NextPage extends Component {
  render() {
    let {count,uri,current} = this.props.pageination;
    return (
      <div className='block nextPage'>
        Seiten:
        {
          count.map((d,i)=>{
            let base = i===0?'':(i+1);
            return <Link key={uri+'_blocks_pagination_'+i} className={classNames({active:(i+1)+''===current+''})} to={'/'+uri+'/'+base}>{(i+1)}</Link>
          })
        }
      </div>
    );
  }
}

export default NextPage;
