import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './components/App';
import './index.css';
import * as serviceWorker from './serviceWorker';
import configureStore from './store';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';

const store = configureStore();
ReactDOM.render(<CookiesProvider><Provider store={store}>
  <Router >
    <Route path='/' component={props => <App/>} />
  </Router>
</Provider></CookiesProvider>, document.getElementById('fw-media'));


serviceWorker.register();
