import React from 'react';
import HtmlToReact, {Parser} from 'html-to-react';
import { getElement } from './elements';

export function parse(html,attributes) {
  var processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);
  var processingInstructions = [
    {
      shouldProcessNode: function (node) {
        return node.attribs&&node.attribs.fw;
      },
      processNode: function (node, children) {
        const Element = getElement(node.attribs.fw);
        return <Element className={node.attribs.class} attributes = {node.attribs} content={children}/>
      }
    },
    {
      shouldProcessNode: function (node) {
        return node.type==='tag'&&node.name==='a'&&(node.attribs.target||(attributes.type&&attributes.type==='outbound'))&&!node.attribs.rel;
      },
      processNode: function (node, children) {
        return <a  className={node.attribs.class} style={node.attribs.stlye} href={node.attribs.href} target='_blank' rel = 'noopener noreferrer'>{children}</a>
      }
    },
    {
      shouldProcessNode: function (node) {
        return true;
      },
      processNode: processNodeDefinitions.processDefaultNode
    }
  ];
  var htmlToReactParser = new Parser();
  return htmlToReactParser.parseWithInstructions(html, ()=>true, processingInstructions);
}
