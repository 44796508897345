import React, { Component } from 'react';
import '../../../../assets/scss/components/blocks/blocks.scss';
import classNames from 'classnames';
import {getBlock, isBlockAllowed} from '../../../../helpers/blocks';
import Image from '../../../elements/Image';

class Cover extends Component {
  render() {
    const { innerBlocks } = this.props.block;
    const { attributes } = this.props;
    let bg = attributes.customOverlayColor;
    let dim = attributes.dimRatio;
    let overlay = {
      ...(bg&&{backgroundColor:bg}),
      ...(dim>=0&&{opacity:dim/100})
    }
    let fit = attributes.focalPoint;
    let image = {
      ...(fit&&{objectPosition: fit.x*100 +'% '+ fit.y*100+'%'})
    }
    return (
      <div className={classNames('block cover',attributes.className,{fullheight:attributes.fullheight,['align-'+attributes.align]:attributes.align,hasParallax:attributes.hasParallax})}>
        <div className='container'>
          <div className={classNames('overlay',attributes.overlayColor)} style={ overlay }/>
          <Image id={attributes.id} width={window.innerWidth} style={image}/>
          <div className='inner'>
            {
              innerBlocks.filter(d=>isBlockAllowed(d.blockName)).map((e,j)=>{
                const Block = getBlock(e.blockName);
                return <Block key={this.props.id+'_entry_'+j} attributes={e.attrs} data={e.innerHTML}/>
              })
            }
          </div>
        </div>
      </div>
    );
  }
}
export default Cover;
