import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../../assets/scss/components/blocks/blocks.scss';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { sendMail, setSubmitted } from '../../../store/settings/actions';

const Input = (attrs) => {
  const { data } = attrs;
  return <label className={classNames({fullWidth:data.width==='full',halfWidth:data.width==='half'})}>
    <input type={data.type} placeholder={data.placeholder} name={attrs.name} required={classNames({required:data.mode==='required'})} aria-label={data.placeholder}/>
  </label>
}

const Textarea = (attrs) => {
  const { data } = attrs;
  return <label className={classNames({fullWidth:data.width==='full',halfWidth:data.width==='half'})}>
    <textarea placeholder={data.placeholder} name={attrs.name} rows='4' required={classNames({required:data.mode==='required'})} aria-label={data.placeholder}/>
  </label>
}
const Optin = (attrs) => {
  const { data } = attrs;
  return <label className={classNames({fullWidth:data.width==='full',halfWidth:data.width==='half'})}>
    <input type='checkbox' placeholder={data.placeholder} name={attrs.name} required aria-label={data.placeholder}/>
    <span>Ich habe die <Link to={'/'+attrs.privacy.uri}>Datenschutzerklärung</Link> gelesen und aktzeptiere sie.</span>
  </label>
}

class Contact extends Component {
  constructor(props){
    super(props);
    this.component = React.createRef();
    this.state = {
      first: true,
    };
  }
  componentDidMount(){
    this.props.setSubmitted(false);
  }
  componentWillUnmount(){
    this.props.setSubmitted(false);
  }
  componentDidUpdate(){
    !this.state.first&&this.validate();
  }
  validate = () => {
    let fields = [...this.component.current.childNodes[0].childNodes];
    return fields.filter(field=>{
      if(field.localName==='label'&&!field.control.validity.valid){
        field.classList.add('invalid');
        return true;
      }else if(field.localName==='label'&&field.control.validity.valid){
        field.classList.remove('invalid');
        return false;
      } else {
        return false;
      }
    }).length
  }
  sendMail = (e) => {
    e.preventDefault();
    let valid = this.validate();
    if(valid===0){
      this.props.sendMail(this.props.block.attrs.data.dgr_contact_wrapper,this.props.block.attrs.id, new FormData(this.component.current.childNodes[0]));
    }
    this.setState({first:false});
  }

  render() {
    const {data} = this.props.block.attrs;
    const fields = data.items;
    return (
      fields&&<div className='block contact' ref={this.component}>
        <form action="#">
          {
            fields.map((d,i)=>{
              const attrs = d[d.acf_fc_layout];
              switch (d.acf_fc_layout) {
                case 'input':
                  return <Input key={'form_'+data.dgr_contact_wrapper+'_'+i} data={attrs} name={'form_'+data.dgr_contact_wrapper+'_item_'+i}/>
                case 'textarea':
                  return <Textarea key={'form_'+data.dgr_contact_wrapper+'_'+i} data={attrs} name={'form_'+data.dgr_contact_wrapper+'_item_'+i}/>
                case 'optin':
                  return <Optin key={'form_'+data.dgr_contact_wrapper+'_'+i} data={attrs} privacy={this.props.privacy} name={'form_'+data.dgr_contact_wrapper+'_item_'+i}/>
                default:
                  return <Input key={'form_'+data.dgr_contact_wrapper+'_'+i} data={attrs} name={'form_'+data.dgr_contact_wrapper+'_item_'+i}/>
              };
            })
          }
          <button onClick={(e)=>this.sendMail(e)}>Absenden</button>
        </form>
      </div>
    );
  }
}

function mapStateToProps(reduxState, props) {
  let privacy = reduxState.content.posts.find(post => post.id+''===reduxState.settings.settings.general.privacy.page);
  return {
    privacy: privacy,
  }
}
export default connect(mapStateToProps,{sendMail, setSubmitted})(Contact);
