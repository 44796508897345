import Dexie from 'dexie';

const db = new Dexie('FWDGRDB');
db.version(1).stores(
  {
    settings: '++id',
    posts: '++id',
    media: '++id',
    menus: '++id',
  }
);
db.open().then(function(){
}).catch(Dexie.OpenFailedError, function (e) {
    console.error ("open failed due to: " + e.inner);
}).catch(Error, function (e) {
    console.error ("Error: " + e.message);
}).catch(function (e) {
    console.error (e);
});

export default db;
