import React, { Component } from 'react';
import '../../../../assets/scss/components/blocks/blocks.scss';
import classNames from 'classnames';
import { parse } from '../../../../helpers/parser';

class Button extends Component {
  render() {
    const { attributes } = this.props;
    return (
      <div className={classNames('block button')}>
        {
          parse(this.props.data, attributes)
        }
      </div>
    );
  }
}
export default Button;
