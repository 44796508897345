import React, { Component } from 'react';
import '../../../../assets/scss/components/blocks/blocks.scss';
import classNames from 'classnames';
import {getBlock, isBlockAllowed} from '../../../../helpers/blocks';
import Image from '../../../elements/Image';

class MediaText extends Component {
  render() {
    const { innerBlocks } = this.props.block;
    const { attributes } = this.props;
    let bg = attributes.customBackgroundColor;
    let style = {
      ...(bg&&{backgroundColor:bg}),
    }
    return (
      <div className={classNames('block media-text',{stacked:attributes.isStackedOnMobile,['align-'+this.props.attributes.align]:this.props.attributes.align})}>
        <div className={classNames('inner',{['position-'+this.props.attributes.mediaPosition]:this.props.attributes.mediaPosition})}>
          <div className='image'>
            <Image id={this.props.attributes.mediaId} width={window.innerWidth} />
          </div>
          <div className={classNames('text',attributes.backgroundColor)} style={style} >
            {
              innerBlocks.filter(d=>isBlockAllowed(d.blockName)).map((e,j)=>{
                const Block = getBlock(e.blockName);
                return <Block key={this.props.id+'_inner_'+j} attributes={e.attrs} data={e.innerHTML} block={e}/>
              })
            }
          </div>
        </div>
      </div>
    );
  }
}
export default MediaText;
