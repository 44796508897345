import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import contentReducer from './content/reducers';
import settingsReducer from './settings/reducers';

const rootReducer = combineReducers({
  content: contentReducer,
  settings: settingsReducer
});

export default function configureStore(){
  return createStore(
    rootReducer,
    compose(
      applyMiddleware(thunk),
      window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
    )
  );
}
