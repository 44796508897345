import Audio from '../components/blocks/core/common/Audio';
import Button from '../components/blocks/core/layout/Button';
import Code from '../components/blocks/core/formatting/Code';
import Columns from '../components/blocks/core/layout/Columns';
import Custom from '../components/blocks/core/formatting/Custom';
import Cover from '../components/blocks/core/common/Cover';
import File from '../components/blocks/core/common/File';
import Heading from '../components/blocks/core/common/Heading';
import Html from '../components/blocks/core/formatting/Html';
import Image from '../components/blocks/core/common/Image';
import Paragraph from '../components/blocks/core/common/Paragraph';
import Preformatted from '../components/blocks/core/formatting/Preformatted';
import List from '../components/blocks/core/common/List';
import MediaText from '../components/blocks/core/layout/MediaText';
import NextPage from '../components/blocks/core/layout/NextPage';
import Quote from '../components/blocks/core/common/Quote';
import Separator from '../components/blocks/core/layout/Separator';
import Spacer from '../components/blocks/core/layout/Spacer';
import Table from '../components/blocks/core/formatting/Table';
import Video from '../components/blocks/core/common/Video';
import Verse from '../components/blocks/core/formatting/Verse';

import Contact from '../components/blocks/custom/Contact';

export function getBlock(name){
  switch (name) {
    case 'core/audio':
      return Audio;
    case 'core/button':
      return Button;
    case 'core/code':
      return Code;
    case 'core/columns':
      return Columns;
    case 'core/custom':
      return Custom;
    case 'core/cover':
      return Cover;
    case 'core/file':
      return File;
    case 'core/heading':
      return Heading;
    case 'core/html':
      return Html;
    case 'core/image':
      return Image;
    case 'core/list':
      return List;
    case 'core/media-text':
      return MediaText;
    case 'core/nextpage':
      return NextPage;
    case 'core/paragraph':
      return Paragraph;
    case 'core/preformatted':
      return Preformatted;
    case 'core/quote':
      return Quote;
    case 'core/separator':
      return Separator;
    case 'core/spacer':
      return Spacer;
    case 'core/table':
      return Table;
    case 'core/verse':
      return Verse;
    case 'core/video':
      return Video;
    case 'acf/fw-dgr-contact-wrapper':
      return Contact;
    default:
      return Paragraph;

  }
}

export function getAllowedBlocks(){
  return [
    'core/audio',
    'core/button',
    'core/code',
    'core/columns',
    // 'core/custom',
    'core/cover',
    'core/file',
    'core/heading',
    'core/html',
    'core/image',
    'core/list',
    'core/media-text',
    'core/nextpage',
    'core/paragraph',
    'core/preformatted',
    'core/quote',
    'core/separator',
    'core/spacer',
    'core/table',
    'core/verse',
    'core/video',
    'acf/fw-dgr-contact-wrapper'
  ];
}

export function isBlockAllowed(block){
  return getAllowedBlocks().indexOf(block)>-1;
}

export function getPagination(blocks){
  if(!blocks){
    return [];
  }
  let result = [[]];
  let lastPage;
  blocks.forEach((d,i)=>{
    result[result.length-1].push(d);
    if(d.blockName==='core/nextpage'){
      lastPage=d;
      result.push([]);
    }
  });
  if(lastPage){
    result[result.length-1].push(lastPage);
  }
  return result;
}
