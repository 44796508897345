import React, { Component } from 'react';
import '../../../../assets/scss/components/blocks/blocks.scss';
import classNames from 'classnames';
import {getBlock, isBlockAllowed} from '../../../../helpers/blocks';

class Columns extends Component {
  render() {
    const { innerBlocks } = this.props.block;
    return (
      <div className={classNames('block columns',{['align-'+this.props.attributes.align]:this.props.attributes.align})}>
        {
          innerBlocks.map((d,i)=>{
            return <div className='column' key={this.props.id+'_inner_'+i}>
              {
                d.innerBlocks.filter(d=>isBlockAllowed(d.blockName)).map((e,j)=>{
                  const Block = getBlock(e.blockName);
                  return <Block key={this.props.id+'_inner_'+i+'_entry_'+j} attributes={e.attrs} data={e.innerHTML} block={e}/>
                })
              }
            </div>
          })
        }
      </div>
    );
  }
}
export default Columns;
