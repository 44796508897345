import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faInstagram, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';

export function getIcon(icon) {
  switch (icon) {
    case 'facebook':
      return <FontAwesomeIcon icon={faFacebookF}/>
    case 'instagram':
      return <FontAwesomeIcon icon={faInstagram}/>
    case 'twitter':
      return <FontAwesomeIcon icon={faTwitter}/>
    case 'youtube':
      return <FontAwesomeIcon icon={faYoutube}/>
    case 'bars':
      return <FontAwesomeIcon icon={faBars}/>
    case 'times':
      return <FontAwesomeIcon icon={faTimes}/>
    default:
      return <FontAwesomeIcon icon={faTwitter}/>

  }
}

export function findIcon(url) {
  if(url.includes('facebook.com')){
    return {
      name: 'facebook',
      icon: getIcon('facebook')
    }
  }
  if(url.includes('instagram.com')){
    return {
      name: 'instagram',
      icon: getIcon('instagram')
    }
  }
  if(url.includes('twitter.com')){
    return {
      name: 'twitter',
      icon: getIcon('twitter')
    }
  }
  if(url.includes('youtube.com')){
    return {
      name: 'youtube',
      icon: getIcon('youtube')
    }
  }
}
