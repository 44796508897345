import { INIT_PATH, POSTS_PATH } from '../../config/api';
import sendRequest from '../../helpers/api';
import { ADD_MEDIA, ADD_MENU, ADD_POST, CACHE_MEDIA } from './types';
import { initSettings, setLoader } from '../settings/actions';
import db from '../../helpers/db';

function initPosts (dispatch, posts) {
  posts.forEach((data,i)=>{
    return dispatch(
      {
        type: ADD_POST, data
      }
    );
  });
}
function initMenus (dispatch, menus) {
  menus.forEach((data,i)=>{
    return dispatch(
      {
        type: ADD_MENU, data
      }
    );
  });
}
function initMedia (dispatch, media) {
  media.forEach((data,i)=>{
    return dispatch(
      {
        type: ADD_MEDIA, data
      }
    );
  });
}

export function init () {
  return dispatch => {
    dispatch(setLoader(true));

    if (navigator.onLine) {
      return new Promise((resolve, reject) => {
        return sendRequest( 'GET' , INIT_PATH )
          .then(
            res => {
              dispatch(initSettings(res.settings));
              var dbSettings = res.settings;
              dbSettings.id = 1;
              db.table('settings').put(dbSettings);
              if( res.menus ) {
                initMenus(dispatch, res.menus);
                db.table('menus').bulkPut(res.menus)
              }
              if( res.posts ) {
                initPosts(dispatch, res.posts);
                db.table('posts').bulkPut(res.posts)
              }
              if( res.media ) {
                initMedia(dispatch, res.media);
                db.table('media').bulkPut(res.media);
              }
              dispatch(setLoader( false, true ));
              resolve();
            }
          )
          .catch(err => {
            dispatch(setLoader(false));
            reject();
          });
      });
    } else {
      db.table('menus')
        .toArray()
        .then((menus) => {
          initMenus(dispatch, menus);
        }
      ).then(()=>{
        db.table('posts')
          .toArray()
          .then((posts) => {
            initPosts(dispatch, posts);
          }
        ).then(()=>{
          db.table('media')
            .toArray()
            .then((media) => {
              initMedia(dispatch, media);
            }
          ).then(()=>{
            db.table('settings')
              .toArray()
              .then((settings) => {
                dispatch(initSettings(settings[0]));
              }
            ).then(()=>{
                dispatch(setLoader( false, true ));
            })
          })
        });
      });
    }
  }
}

export function maybeRequestPost(uri) {
  return ( dispatch, getState ) => {
    if (navigator.onLine) {
      if(uri&&!getState().settings.loading&&getState().settings.init&&!getState().content.posts.find(post=>post.uri===uri)) {
        requestPost(dispatch, uri);
      }
    }
  }
}

function requestPost (dispatch, uri) {
    dispatch(setLoader(true));
    return new Promise((resolve, reject) => {
      return sendRequest( 'GET' , POSTS_PATH+uri )
        .then(
          res => {
            if( res.posts ) {
              initPosts(dispatch, res.posts);
              db.table('posts').put(res.posts);
            }
            if( res.media ) {
              initMedia(dispatch, res.media);
              db.table('media').put(res.media);
            }
            dispatch(setLoader(false));
            return resolve();
          }
        )
        .catch(error  => {
          dispatch(setLoader(false));
          return reject(error);
        });
    });
}

export function cacheMedia (id) {
  return {
    type: CACHE_MEDIA, id
  }
}
