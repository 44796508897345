import update from 'react-addons-update';
import { INIT_SETTINGS, SET_LOADER, SUBMITTED, SET_HAMBURGER } from './types';

const DEFAULT_STATE = {
  settings: {}, loading: false, init: false, hamburger: false,
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case INIT_SETTINGS:{
      return update(state, {
        settings: {
          $set: action.settings
        }
      });
    }
    case SET_LOADER: {
      let init = state.init;
      if(action.init) {
        init = action.init;
      }
      return update(state, {
        loading: {
          $set: action.value
        },
        init: {
          $set: init
        }
      });
    }
    case SUBMITTED: {
      return update(state, {
        submitted: {
          $set: action.value
        }
      });
    }
    case SET_HAMBURGER:{
      return update(state, {
        hamburger: {
          $set: action.value
        }
      });
    }
    default:
      return state;
  }
};
