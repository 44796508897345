import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { getBlock, isBlockAllowed, getPagination } from '../../helpers/blocks';
import '../../assets/scss/components/templates/Post.scss';
class Home extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const { post } = this.props;
    let { id } = this.props.match.params;
    id = id?parseInt(id):1;
    let pagination = getPagination(post?post.content:'');
    return (
      <>
        <article className='Post'>
          <Helmet>
            <title>{post.seo&&post.seo.title}</title>
            <meta name='Description' content={post.seo.description}/>
            <link rel='canonical' href={window.location.href} />
            <meta property='og:title' content={post.seo.og.title}/>
            <meta property='og:description' content={post.seo.og.description}/>
            <meta property='og:image' content={post.seo.og.image}/>
          </Helmet>
          {
            post.show_title&&<header className='header'>
              <h1>{post.seo&&post.title}</h1>
            </header>
          }
          <section className='content'>
            {
              pagination[id-1].filter(d=>isBlockAllowed(d.blockName)).map((d,i)=>{
                const Block = getBlock(d.blockName);
                return <Block key={post.uri+'_blocks_'+i} id={post.uri+'_blocks_'+i} attributes={d.attrs} data={d.innerHTML} block={d} pageination={
                  d.blockName==='core/nextpage'?{
                    count:getPagination(post.content),
                    current:id,
                    uri:this.props.match.params.uri,
                  }:false
                } />
              })
            }
            {

              // <form action="#">
              //   <h2 className="fullWidth">Kontakt</h2>
              //   <label className="fullWidth">
              //     <input type="text" required="" name="Name" placeholder="Ihr Name" aria-label="Ihr Name"/>
              //   </label>
              //   <label className="fullWidth">
              //     <input type="email" required="" name="E-Mail-Adresse" placeholder="Ihre Email Adresse" aria-label="Ihre Email Adress"/>
              //   </label>
              //   <label className="fullWidth">
              //     <input type="tel" required="" name="Telefon" placeholder="Ihre Telefon Nummer" aria-label="Ihre Telefon Nummer"/>
              //   </label>
              //   <label className="fullWidth">
              //     <textarea rows="4" name="Kurze Projektbeschreibung" placeholder="Lorem Ipsum" aria-label="Ihre Nachricht"></textarea>
              //   </label>
              //   <label className="fullWidth">
              //     <input type="checkbox" required="" name="Consent" />
              //     <span>Ich habe die <Link to={'/'+this.props.privacy.uri}>Datenschutzerklärung</Link> gelesen und aktzeptiere sie.
              //     </span>
              //   </label>
              //   <label className="fullWidth potty">
              //     <span>EMail</span>
              //     <input type="email" name="email" autoComplete="false" placeholder="Your email adress" />
              //   </label>
              //   <button>Absenden</button>
              // </form>
            }
          </section>
        </article>
      </>
    );
  }
}

function mapStateToProps(reduxState, props) {
  let post = reduxState.content.posts.find(post => post.id+''===reduxState.settings.settings.general.landingpage);
  return {
    post: post,
  }
}
export default connect(mapStateToProps,{})(Home);
